import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import Modal from '../../../components/Modal'
import PresenterModal from '../../../components/PresenterModal'
import ToggleModal from '../../../components/ToggleModal'

const Wrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerWidths.mediumLarge};
  margin-left: auto;
  margin-right: auto;

  .keynoteSpeakers-container {
    max-width: ${props => props.theme.containerWidths.small};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px 9px;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;

    button {
      display: block;
      cursor: pointer;
      padding: 0 20px;
      text-align: center;
      color: #000;

      .gatsby-image-wrapper,
      .gatsby-image-wrapper img {
        border-radius: 50%;
      }

      .gatsby-image-wrapper {
        width: 100% !important; // override
        height: auto !important; // override
        margin-bottom: 12px;

        picture {
          img {
            position: relative !important;
          }
        }
      }

      .speaker--title {
        margin-top: 4px;
        margin-bottom: 4px;
      }

      .link-underline {
        margin: 0;
        font-size: 13px;
        line-height: 16px;
      }
    }

    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      max-width: ${props => props.theme.containerWidths.smallMedium};
      grid-template-columns: repeat(auto-fit, minmax(164px, 200px));
    }
  }
`

const SpeakersIndex = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBio {
        edges {
          node {
            id
            name
            image {
              description
              fixed(width: 400, quality: 65) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const bios = data.allContentfulBio.edges
  const keynoteSpeakerNames = [
    'Cara Brook',
    'Matthew Hussey',
    'Judi Holler',
    'Margaret Paul',
    'Bob Heilig',
  ]

  const keynoteSpeakers = []

  for (const name of keynoteSpeakerNames) {
    bios.filter(bio => bio.node.name === name && keynoteSpeakers.push(bio.node))
  }

  return (
    <Wrapper>
      <section className="section wrap">
        <h3 className="headline--reunion center-align">Speakers</h3>
        <div className="keynoteSpeakers-container">
          {keynoteSpeakers.map(speaker => (
            <ToggleModal
              key={speaker.id}
              toggle={show => (
                <button onClick={show}>
                  {speaker.image && (
                    <Img fixed={speaker.image.fixed} alt={speaker.name} />
                  )}
                  <p className="title">{speaker.name}</p>
                  <p className="speaker--title">{speaker.title}</p>
                  <p className="link-underline">View Bio</p>
                </button>
              )}
              content={(hide, isShown) => (
                <Modal key={speaker.id} hide={hide} isShown={isShown}>
                  <PresenterModal {...speaker} />
                </Modal>
              )}
            />
          ))}
        </div>
      </section>
      <section className="section wrap">
        <h3 className="headline--reunion center-align">Love from Artists</h3>
        <p>
          One of our favorite things is hearing from our amazing Artists. Your stories and experiences inspire,
          motivate, and light a fire within our community. If you want to share your love of Seint’s products,
          community, or mission at Seint’s virtual meeting, LOVE, please fill out the form below by Sunday,
          December 16, 2023 to be considered. We will be announcing our “Love from Artist” speakers early
          January!
        </p>
        <p className="center-align">
          <a
            className="btn--submit"
            style={{ textDecoration: 'none' }}
            href="https://docs.google.com/forms/d/1jErnAm3-Nnp22Pwh-bZ2rmZs0u885ZdmqyYGVG8n1KM/prefill"
            target="_blank"
            rel="noopener noreferrer"
          >
            Love from Artists Form ↗
          </a>
        </p>
      </section>
    </Wrapper>
  )
}

export default SpeakersIndex
