import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

/*
 * The <PresenterModal> should receive an `event` object which must contain:
 * * title
 * * startTime
 * The `event` object can optionally contain:
 * * endTime
 * * body
 * This `event` object will be passed to a <CalendarICS> child component of <EventModal>
 * The component also received event data as props which must include:
 * * title
 * * startTime
 * And optionally contain the props:
 * * endTime
 * * body
 * The component is used inside a <Modal>, which is inside a <ToggleModal> that will trigger this component to be shown
 */

/**
 * @example
 * <ArticlesList>
 *   {events.map(({ node: event }) => {
 *     return (
 *       <ToggleModal
 *         key={event.id}
 *         toggle={show => (
 *           <EventCard
 *             handleClick={show}
 *             title={event.title}
 *             startTime={event.startTime}
 *             endTime={event.endTime}
 *           />
 *         )}
 *         content={(hide, isShown) => (
 *           <Modal hide={hide} isShown={isShown}>
 *             <EventModal
 *               event={event}
 *               title={event.title}
 *               body={
 *                 event.body && event.body.childMarkdownRemark.html
 *               }
 *               startTime={event.startTime}
 *               endTime={event.endTime}
 *             />
 *           </Modal>
 *         )}
 *       />
 *     )
 *   })}
 * </ArticlesList>
 */

const Outer = styled.article`
  > a {
    margin-top: 20px;
  }

  .btn--pill {
    min-width: 0;
    padding: 10px 15px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    > a {
      margin-top: 30px;
    }
  }
`

const Header = styled.div`
  text-align: center;
  padding-top: 15px;

  .gatsby-image-wrapper {
    max-width: 170px;
    max-height: 170px;
    margin-bottom: 24px;

    &,
    img {
      border-radius: 50%;
    }
  }
`

const Title = styled.h3`
  margin-bottom: 20px;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
  }
`

const Body = styled.div`
  strong {
    font-weight: 600;
  }
`

const PresenterModal = ({ name, image, body }) => {
  return (
    <Outer>
      <Header>
        {image && <Img fixed={image.fixed} alt={name} />}
        <Title className="headline--medium" title={name}>
          {name}
        </Title>
      </Header>
      {body && (
        <Body
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        />
      )}
    </Outer>
  )
}

export default PresenterModal
